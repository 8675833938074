// import logo from './LogoV1_600px.png';
import './App.css';

function App() {
  return (
    <div>
      <div className="header">
        <div className="inner-header flex">
          <div className="h1">Absum<div className="h2">Corporation</div>
          </div>
        </div>
      <div>
      <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
      <defs>
      <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
      <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
      <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
      <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
      <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
      </svg>
      </div>


      </div>



    <div className="content flex">
      <p><span className="content-title">Harmonizing Complexity</span><br /><br />Software, architecture and design.<br />Development, review and consultation.<br />Music production.
      <br /><br /><br />
      Contact information: tk(at)absum.net<br />

     <hr />

      <span className='footer-text'><strong>Absum Corporation Oy</strong><br />
Tuusula, Finland<br />
Y-Tunnus: 3412189-8<br />
VAT ID: FI34121898</span></p>
    </div>
  </div>
  );
}

export default App;
